<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card no-body class="faq-search"
        :style="{ backgroundImage: `url(${require('@/assets/images/banner/banner-41.jpg')})` }">
        <b-card-body class="text-center" style="height:300px">
          <h2 class="text-white">
          </h2>
          <b-card-text class="mb-2" style="color:darkgray">
          </b-card-text>
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs vertical content-class="col-12 col-md-8 col-lg-9" pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12" nav-class="nav-left">

        <!-- payment tab -->
        <b-tab v-for="(categoryObj, categoryName, index) in faqData" :key="categoryName" :active="index">

          <!-- title -->
          <template #title>
            <feather-icon :icon="categoryObj.icon" size="18" class="mr-1" />
            <span class="font-weight-bold">{{ categoryObj.title }}</span>
          </template>

          <faq-question-answer :options="categoryObj" />
        </b-tab>
        <!--/ payment tab -->

        <!-- sitting lady image -->
        <template #tabs-end>
          <b-img fluid :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-auto" />
        </template>
        <!--/ sitting lady image -->
      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->

    <!-- contact us -->
    <!-- <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>You still have a question?</h2>
          <b-card-text class="mb-3">
            If you cannot find a question in our FAQ, you can always contact us. We will answer to you shortly!
          </b-card-text>
        </b-col>
        <b-col sm="6">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="PhoneCallIcon" size="18" />
            </b-avatar>
            <h4>+56 9 7667 9597</h4>
            <span class="text-body">We are always happy to help!</span>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="MailIcon" size="18" />
            </b-avatar>
            <h4>soporte@altumlab.cl</h4>
            <span class="text-body">Best way to get answer faster!</span>
          </b-card>
        </b-col>
      </b-row>
    </section> -->
    <!--/ contact us -->
  </div>
</template>
  
<script>
import {
  BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, BTabs, BTab, BImg, BRow, BCol, BAvatar,
} from 'bootstrap-vue'
import FaqQuestionAnswer from './FaqQuestionAnswer.vue'

import useApiServices from '@/services/useApiServices.js';

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    FaqQuestionAnswer,
  },
  data() {
    return {
      faqSearchQuery: '',
      faqData: {},

      clientsMe: useApiServices.clientsMe,
    }
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    fetchData() {
      this.faqData = [
        {
          "icon": "ArchiveIcon",
          "qandA": [
            {
              "ans": "Bienvenido a nuestra plataforma web. Estos términos y condiciones constituyen un contrato legalmente vinculante entre usted y nosotros en relación con el uso de nuestros servicios. Nuestra plataforma web proporciona modelos de predicción y optimización para empresas extractivas, manufactureras y agroindustriales. Al utilizar nuestros servicios, el Usuario acepta cumplir con estos Términos y cualquier otra política o regla publicada en nuestro sitio web.",
              "question": "Servicios"
            },
            {
              "ans": "Todos los derechos de propiedad intelectual relacionados con nuestra plataforma web, incluyendo pero no limitado a marcas registradas, patentes y derechos de autor, son propiedad exclusiva de la Empresa. Los usuarios pueden utilizar la plataforma web solo con fines comerciales, no comerciales y personales, y no pueden reproducir, distribuir o modificar ningún contenido de la plataforma web sin el permiso previo por escrito de la Empresa.",
              "question": "Propiedad intelectual"
            },
            {
              "ans": "La Empresa no será responsable por daños directos, indirectos, especiales, incidentales, consecuentes, punitivos o cualquier otra forma de daño que surja del uso de nuestra plataforma web. La Empresa no garantiza la exactitud o integridad de la información proporcionada por la plataforma web y se reserva el derecho de modificar o suspender cualquier servicio sin previo aviso.",
              "question": "Limitación de responsabilidad"
            },
            {
              "ans": "La Empresa puede terminar estos Términos y cualquier acceso del Usuario a la plataforma web en cualquier momento y por cualquier motivo. Si se termina la relación entre la Empresa y el Usuario, los Términos seguirán siendo aplicables.",
              "question": "Terminación"
            }
          ],
          "title": "Términos y condiciones",
          "subtitle": ""
        },
        {
          "icon": "BookOpenIcon",
          "qandA": [
            {
              "ans": "Nuestra empresa se preocupa por la privacidad y protección de los datos personales de nuestros clientes y usuarios. Esta Política de Privacidad describe cómo recopilamos, utilizamos y compartimos su información personal.",
              "question": "Introducción"
            },
            {
              "ans": "Recopilamos información personal como nombre, correo electrónico, dirección, teléfono, información de facturación, información de pago y cualquier otra información que el Usuario proporcione al utilizar nuestros servicios.",
              "question": "Información personal recopilada"
            },
            {
              "ans": "La información personal que recopilamos se utiliza para brindar nuestros servicios, procesar pagos, enviar correos electrónicos de marketing y mejorar la plataforma web. La Empresa no venderá ni compartirá la información personal del Usuario con terceros sin su consentimiento expreso.",
              "question": "Uso de la información personal"
            },
            {
              "ans": "Nos comprometemos a proteger la información personal del Usuario y utilizamos medidas de seguridad técnicas y organizativas para garantizar su seguridad.",
              "question": "Seguridad de la información personal"
            },
            {
              "ans": "Nos reservamos el derecho de modificar o actualizar esta Política de Privacidad en cualquier momento sin previo aviso. Cualquier cambio se publicará en nuestro sitio web y se aplicará inmediatamente.",
              "question": "Cambios en la Política de Privacidad"
            },
            {
              "ans": "Nos comprometemos a proteger la información productiva del cliente y utilizamos medidas de seguridad técnicas y organizativas para garantizar su seguridad. Esta información es tratada como confidencial y solo se utiliza para proporcionar nuestros servicios. La Empresa no divulgará, venderá ni compartirá la información productiva del cliente con terceros sin su consentimiento expreso, a menos que sea requerido por ley o una orden judicial. <br><br>Además, la Empresa se compromete a mantener la confidencialidad de la información productiva del cliente y a no utilizarla para ningún otro propósito que no sea el de proporcionar nuestros servicios. Nos aseguramos de que solo el personal autorizado tenga acceso a esta información y se compromete a tomar todas las medidas necesarias para prevenir el acceso no autorizado, la divulgación, alteración o destrucción de la información productiva del cliente.<br><br>En caso de que se produzca una brecha de seguridad que comprometa la información productiva del cliente, nos comprometemos a notificar al cliente tan pronto como sea posible y tomar las medidas necesarias para remediar la situación.",
              "question": "Resguardo de la información productiva del cliente"
            },

          ],
          "title": "Política de privacidad",
          "subtitle": ""
        }
      ]
    },
  },
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
  

